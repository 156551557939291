.container {
  width: 1000px;
  height: 100vh;
  position: relative;
  @media (max-width: 900px) {
    display: none;
  }
  &.blurActive {
    filter: blur(3px);
  }
}
.img {
  position: absolute;
  left: 0px;
  height: 100%;
  width: auto;
  overflow: hidden;
  @media (min-width: 1400px) {
    left: unset;
    right: 0px;
  }
}
