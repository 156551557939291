.container {
  width: 50vw;
  min-width: 600px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  @media (min-width: 1400px) {
    width: 100vw;
  }
  .topContainer {
    width: 100%;
    height: 30%;
    @media (max-width: 600px) {
      height: 200px;
    }
  }
  .bottomContainer {
    width: 100%;
    height: 400px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 60vh;
    @media (max-width: 800px) and (orientation: portrait) {
      justify-content: flex-start;
    }
  }
  &.blurActive {
    filter: blur(3px);
  }
}
