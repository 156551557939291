.container {
  position: absolute;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 5%;
  z-index: 60;
  justify-content: center;
  @media (max-width: 900px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 30%;
  }
}
.img {
  position: relative;
  width: 100%;
  z-index: 99;
  resize: none;
  max-width: 1920px;
  justify-self: center;
}
.closeButton {
  border: none;
  background: none;
  position: absolute;
  z-index: 100;
  padding: 20px;
  right: 0;
  top: 0;
  font-size: 1.2rem;
  outline: none;
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
}
