.logo {
  margin: 60px;
  margin-left: 75px;
  width: 150px;
  height: auto;
  @media(max-width: 900px) {
    width: 200px;

  }
}
