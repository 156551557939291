.item {
  color: #5c5c5c;
  font-size: 10em;
  max-width: 50vw;
  position: relative;
  display: flex;
  flex-direction: row;
  font-weight: 200;
  min-width: 540px;
  cursor: pointer;
  @media (max-width: 650px) {
    min-width: unset;
  }
  @media (max-width: 1700px) {
    font-size: 6em;
    line-height: 1.5em;
  }
  @media (min-width: 2800px) {
    font-size: 15em;
  }
  &::before {
    position: absolute;
    top: 15%;
    left: -80px;
    content: "";
    height: 70%;
    width: 50px;
    background-position: center;
    background-repeat: no-repeat;
  }

  &:hover {
    color: white;
    font-weight: 700;
    &::before {
      filter: none;
    }
    &.audi {
      &::before {
        background-image: url("../img/audi-icon-white.png");
      }
    }
    &.seat {
      &::before {
        background-image: url("../img/seat-icon-white.png");
      }
    }
    &.skoda {
      &::before {
        background-image: url("../img/skoda-icon-white.png");
      }
    }
    &.vw {
      &::before {
        background-image: url("../img/vw-icon-white.png");
      }
    }
    &.used {
      &::before {
        background-image: url("../img/used-cars-icon-white.png");
      }
    }
        &.ub {
      &::before {
        background-image: url("../img/used-cars-icon-white.png");
      }
    }
    &.parts {
      &::before {
        background-image: url("../img/parts-icon-white.png");
      }
    }
  }

  &.audi {
    &::before {
      background-image: url("../img/audi-icon.png");
    }
  }
  &.seat {
    &::before {
      background-image: url("../img/seat-icon.png");
    }
  }
  &.skoda {
    &::before {
      background-image: url("../img/skoda-icon.png");
    }
  }
  &.vw {
    &::before {
      background-image: url("../img/vw-icon.png");
    }
  }
  &.used {
    &::before {
      background-image: url("../img/used-cars-icon.png");
    }
  }
  &.parts {
    &::before {
      background-image: url("../img/parts-icon.png");
    }
  }
}
