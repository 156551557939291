.list {
  list-style-type: none;
  margin-left: 20%;
  //margin-top: 100px;
  width: 100%;
  z-index: 50;
  @media (max-width: 600px) {
    margin-left: 100px;
  }
  @media (max-width: 400px) {
    margin-left: 60px;
  }
}
