.app {
  height: 100vh;
  display: flex;
  overflow: hidden;
  background-color: #101010;
  position: relative;
  font-family: "Montserrat", sans-serif;
}

.madebyImg {
  position: absolute;
  right: 100px;
  bottom: 50px;
}
